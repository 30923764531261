<template>
	<div class="blog">

		<Head :title="articleInfo.title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="articleInfo.subTitle" :subTitle2="Head.SubTitle2" :bgUrl="$functions.getImgLink(articleInfo.img.main)"
			:bgUrlLight="$functions.getImgLink(articleInfo.img.main)" :bgUrlLazy="Head.bgUrlLazy" :offset="Head.offset" :height="Head.height"
			:overlayOpacity="Head.overlayOpacity" :overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur"
			:transitionRotate="Head.transitionRotate" :titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 mx-auto" max-width="960" color="transparent">
				<v-col cols="12" v-if="articleInfo.img.secondary">
					<v-img :src="$functions.getImgLink(articleInfo.img.secondary)"></v-img>
				</v-col>

				<v-col cols="12">
					<v-card class="mx-auto pa-4">
						<v-col cols="12">
							<span class="caption">
								<p>
									<v-icon small left>mdi-account</v-icon> Écrit par {{ articleInfo.author.firstName }}
									{{ articleInfo.author.lastName }}
								</p>
								<p>
									<v-icon small left>mdi-clock</v-icon> Publication le
									{{ $functions.getDateFormat(articleInfo.creationDate) }}
								</p>
								<template v-if="articleInfo.modificationAuthor">
									<p class="pt-4">
										<v-icon small left>mdi-tooltip-edit</v-icon> Modification le
										{{ $functions.getDateFormat(articleInfo.modificationDate) }}<br />
									</p>
									<p>
										Par {{ articleInfo.modificationAuthor.firstName }}
										{{ articleInfo.modificationAuthor.lastName }}
									</p>
								</template>
							</span>
						</v-col>
					</v-card>
				</v-col>

				<v-col cols="12">
					<v-card class="mx-auto pa-4">

						<v-col cols="12">

							<h2 class="display-1 mb-6">{{ articleInfo.title }}</h2>

							<h3 class="headline font-weight-light mb-6">{{ articleInfo.subTitle }}</h3>

							<span v-html="articleInfo.shortDesc"></span>

							<v-divider class="my-5" v-if="articleInfo.shortDesc"></v-divider>

							<span v-html="articleInfo.longDesc"></span>

							<span v-html="articleInfo.disclamer"></span>

						</v-col>

					</v-card>
				</v-col>

				<v-card-actions>
					<v-row>
						<v-col cols="12">
							<v-form>
								<v-row>
									<v-col cols="12">
										<v-btn color="error" :block="$vuetify.breakpoint.xsOnly"
											class="neon evidence my-4 float-left" @click="dialog.delete = true"
											outlined>Supprimer
											l'article</v-btn>
										<v-btn color="primary" :block="$vuetify.breakpoint.xsOnly"
											class="neon evidence my-4 float-right" :to="`/blog/${id}/add/${idArticle}`"
											outlined>
											Modifier l'article
										</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</v-col>
					</v-row>
				</v-card-actions>

			</v-card>

		</v-container>


		<v-dialog v-model="dialog.delete" max-width="600">
			<v-card class="glassmorphism" color="transparent">
				<ButtonTrigger title="Supprimer le Blog" small whiteText :noButton="$vuetify.breakpoint.smAndUp" icon
					content="" @click.native="dialog.delete = false" />
				<v-col cols="12">
					<v-card class="bt-error">
						<v-card-title class="headline">
							Voulez-vous supprimer cet article ?
						</v-card-title>
						<v-card-text>
							<p>Après avoir cliqué sur "Confirmer la suppression", l'article "{{ articleInfo.title }}"
								sera
								définitivement supprimé.</p>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="error" class="neon evidence mb-4"
								@click="removeArticle()">
								Confirmer la suppression
							</v-btn>
							<v-btn :block="$vuetify.breakpoint.xsOnly" outlined color="success"
								class="neon evidence mb-4 float-right" text @click="dialog.delete = false">
								Annuler
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'

	export default {
		head: {
			title: {
				inner: "Articles - Blog"
			}
		},
		name: 'Articles',
		props: {
			'id': {
				type: String
			},
			'idArticle': {
				type: String
			}
		},
		components: {
			Head,
			ButtonTrigger,
		},
		data() {
			return {
				userInfo: this.$models.user,
				articleInfo: this.$models.blogArticle,

				blogInfo: {},
				articles: [],

				dialog: {
					delete: false
				},

				Head: {
					Title: 'Blogs',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'bold', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					offset: "15vh",
					height: "30vh",

					overlayOpacity: "0.5",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
				pictures: []
			};
		},
		methods: {
			removeArticle() {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id).collection("articles").doc(this.articleToDelete.id), "L'article a bien été supprimé.");
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.onSnapshot(doc => {
						this.blogInfo = {
							...doc.data(),
							id: doc.id
						}
					});

				this.$db.collection("companies").doc(this.userInfo.company).collection("blogs").doc(this.id)
					.collection("articles").doc(this.idArticle).onSnapshot(document => {
						this.$db.collection("users").doc(document.data().author).get().then(doc => {
							this.articleInfo = {
								...document.data(),
								author: {
									...doc.data(),
									id: doc.id
								},
								id: document.id
							};

							if (document.data().modificationAuthor) {
								this.$db.collection("users").doc(document.data().modificationAuthor)
									.get().then(doc => {
										this.articleInfo.modificationAuthor = {
											...doc.data(),
											id: doc.id
										};
									});
							}

							this.pictures.push({
								src: this.articleInfo.img.main
							}, {
								src: this.articleInfo.img.secondary
							});
						});
					});
			});
		}
	}

</script>
