<template>
	<v-card-title :class="$vuetify.breakpoint.smAndUp ? 'pt-6' : 'pt-8'">
		<h2 class="font-weight-regular" :class="whiteText ? 'white--text' : ''">{{title}}</h2>
		<v-spacer></v-spacer>
		<!-- Justify to Right -->
		<v-btn :small="small" :color="whiteText ? 'white' : $vuetify.theme.dark ? 'white' : 'black'" v-if="noButton"
			:icon="icon" :class="$vuetify.breakpoint.smAndUp ? 'mt-0' : 'mt-6'" outlined
			:block="$vuetify.breakpoint.xsOnly">
			{{content}} <v-icon :small="small" v-if="icon">{{mdi}}</v-icon>
		</v-btn>
	</v-card-title>
</template>

<script>
	export default {
		props: {
			content: {
				type: String,
				default: 'Default Button'
			},
			title: {
				type: String,
				default: 'Default Title'
			},
			noButton: {
				type: Boolean,
				default: true
			},
			icon: {
				type: Boolean,
				default: false,
			},
			mdi: {
				type: String,
				default: 'mdi-close',
			},
			whiteText: {
				type: Boolean,
				default: false,
			},
			small: {
				type: Boolean,
				default: false,
			},
		},
	}

</script>
